.divider {
  display: flex;
  margin-bottom: 0.5rem;
  margin-top: 1.5rem;
}

.divider-text {
  text-transform: uppercase;
  font-weight: bold;
  color: #002a57;
  font-size: 0.8rem;
  margin-right: 0.5rem;
}

.divider-line {
  flex-grow: 1;
  margin-block: auto;
}
