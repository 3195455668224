.navbar {
  border-bottom: $navbar-border-bottom;

  @include media-breakpoint-down(md) {
    width: 100vw;
  }
}

.navbar-brand {
  font-weight: $navbar-brand-font-weight;
}

.nav-icon,
.nav-flag {
  padding: .1rem .8rem;
  display: block;
  font-size: 1.5rem;
  color: $gray-600;
  transition: $transition-appearance-fast;
  line-height: 1.4;

  &:after {
    display: none !important;
  }

  &:hover,
  &.active {
    color: $primary;
  }

  svg,
  .feather {
    width: 20px;
    height: 20px;
  }
}

.nav-item {
  .indicator {
    background: $primary;
    box-shadow: $box-shadow;
    border-radius: 50%;
    display: block;
    height: 18px;
    width: 18px;
    padding: 1px;
    position: absolute;
    top: 0;
    right: -8px;
    text-align: center;
    transition: top .1s ease-out;
    font-size: 0.675rem;
    color: $white;
  }

  &:hover .indicator {
    top: -4px;
  }

  a:focus {
    outline: 0;
  }
}

@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .navbar .avatar {
    max-height: 47px;
  }
}

@include media-breakpoint-down(xs) {
  .navbar {
    padding: 0.75rem;
  }

  .nav-icon {
    padding: .1rem .75rem;
  }

  .dropdown,
  .dropleft,
  .dropright,
  .dropup {
    position: inherit;
  }

  .navbar-expand .navbar-nav .dropdown-menu-lg {
    min-width: 100%;
  }

  .nav-item .nav-link:after {
    display: none;
  }
}

.nav-flag img {
  border-radius: 50%;
  width: 20px;
  height: 20px;
  object-fit: cover;
}

.group-options {
  position: absolute;
  top: 4.2rem;
  margin-left: 0.8rem;
  width: 70%;
  z-index: 1;
  overflow-y: scroll;
  max-height: 320px;
}

.list-group-item {
  height: 3rem;
  display: flex;
  align-items: center;
  transition: 0.2s;
}

.list-group-item:hover {
  background-color: #e0f1f7 !important;
}

.notify-list {
  height: 6.4rem;
}

.group-options a {
  color: #292929;
  transition: 0.1s;
}

.group-options a:hover {
  font-weight: bold;
}