.dre-list {
  list-style: none;
  padding: 0;
  margin-bottom: 0 !important;
}

.dre-row {
  border-radius: "5px";
  padding: "2px 3px";
  background-color: transparent;
  height: "23px";
  display: flex;
  justify-content: space-between;
  cursor: pointer;
}

.dre-row > span {
  font-size: 0.85rem;
}

.dre-row--even {
  background: white;
}

.dre-row--description {
  font-family: "Roboto Mono";
}

.dre-row--item {
  position: relative;
  height: 1.5em;
}

.dre-row:hover {
  background-color: rgba(0, 0, 0, 0.1);
}

.bg-progress-color1 {
  background-color: $blue;
}

.bg-progress-color2 {
  background-color: $red;
}

.bg-progress-color3 {
  background-color: $yellow;
}

.bg-progress-color4 {
  background-color: $green;
}

.bg-progress-color5 {
  background-color: $cyan;
}

.bg-progress-color6 {
  background-color: $pink;
}

.bg-progress-color7 {
  background-color: #0033f4;
}

.bg-progress-color8 {
  background-color: #ff5200;
}

.bg-progress-color9 {
  background-color: #c99a00;
}

.bg-progress-color10 {
  background-color: #34874e;
}

.bg-progress-color11 {
  background-color: #25686d;
}

.bg-progress-color12 {
  background-color: #6133b0;
}

.bg-progress-color13 {
  background-color: #20113a;
}

.bg-progress-color14 {
  background-color: #00263b;
}

.bg-progress-color15 {
  background-color: #183d23;
}

.bg-progress-color16 {
  background-color: #745900;
}

.bg-progress-color17 {
  background-color: #6f0000;
}

.bg-progress-color18 {
  background-color: #007892;
}

.progress-subtitle {
  display: flex;
  width: 100%;
  overflow: hidden;
}

.progress-subtitle span {
  font-size: 0.725rem;
}
