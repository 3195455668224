.form-control-no-border {
  border: 0;
  border-radius: 0;
  box-shadow: none;

  &:focus {
    box-shadow: none;
    outline: 0;
  }
}

.form-label {
  margin-bottom: 12px !important;
}
