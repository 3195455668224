.card {
  > .dataTables_wrapper .table.dataTable,
  > .table,
  > .table-responsive .table,
  > .table-responsive-sm .table,
  > .table-responsive-md .table,
  > .table-responsive-lg .table,
  > .table-responsive-xl .table {
    border-right: 0;
    border-bottom: 0;
    border-left: 0;
    margin-bottom: 0;

    td:first-child,
    th:first-child {
      border-left: 0;
      padding-left: $spacer * 1.25;
    }

    td:last-child,
    th:last-child {
      border-right: 0;
      padding-right: $spacer * 1.25;
    }

    tr:first-child td,
    tr:first-child th {
      border-top: 0;
    }

    tr:last-child td {
      border-bottom: 0;
    }
  }

  .card-header + .table {
    border-top: 0;
  }
}

.table td {
  vertical-align: baseline;
  font-size: 0.775rem;
}

.table th {
  font-size: 0.775rem;
}

.table-action {
  a {
    color: $gray-600;

    &:hover {
      color: $gray-900;
    }
  }

  .feather {
    width: 18px;
    height: 18px;
  }
}

.table .child-table > tbody > tr > td {
  vertical-align: middle;
  padding: 5px !important;
}

.card > .dataTables_wrapper {
  .table.dataTable {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }

  .dataTables_info {
    padding: 1rem 1.25rem;
  }

  .dataTables_paginate {
    padding: 0.6rem 1.25rem;
  }
}

.dt-bootstrap4 {
  width: calc(100% - 2px);
}

.row-expand-select {
  background-color: #d2e3f5;
}

.react-bootstrap-table .row-expand-slide-appear-active {
  -webkit-transition: max-height 1s ease-out !important;
  transition: max-height 1s ease-out !important;
}

.react-bootstrap-table .row-expand-slide-exit-active {
  -webkit-transition: max-height 0.4s ease-out !important;
  transition: max-height 0.4s ease-out !important;
}

.w-auto th {
  padding: 0.75rem 5px;
}

.w-auto .detail-table th {
  padding: 0.3rem 5px;
}

.w-auto td {
  padding: 0.5rem 5px;
}

.w-auto .detail-table td {
  padding: 0.3rem 5px;
}

.borderless-table th {
  border-top: none !important;
  width: auto;
  overflow-x: auto;
  background-color: #606060;
  color: #fff;
}

.borderless-table tr td:first-child,
.borderless-table tr th:first-child {
  padding-left: 1.25rem !important;
}

.borderless-table tr td:last-child,
.borderless-table tr th:last-child {
  padding-right: 1.25rem !important;
}

.borderless-table tr td:only-child,
.borderless-table tr th:only-child {
  padding: 5px !important;
}

.table {
  margin-bottom: 0 !important;
  background-color: #fff;
}

.child-table {
  font-size: 0.775rem !important;
}

.child-table th {
  padding: 0.5em 5px !important;
  background-color: rgba($color: #000000, $alpha: 0.05);
  color: #495057;
}

// Scrollable table

.scrollable-table-wrapper {
  position: relative;
  z-index: 0;
  margin: auto;
  overflow-y: auto;
  overflow-x: hidden;
  max-height: 600px;
}

.scrollable-table {
  border-collapse: unset;
  border-spacing: 0;
}

.scrollable-table thead {
  background: #fff;
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  z-index: 100;
  border-top: 1px solid #e5e9f2;
  border-bottom: 1px solid #e5e9f2;
}

// .scrollable-table tfoot {
//   position: -webkit-sticky;
//   position: sticky;
//   bottom: 0;
//   z-index: 100;
//   background: #fff;
//   text-align: right;
// }

.scrollable-table tbody {
  padding-top: 1px;
}

.nowrap tbody td {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.nowrap {
  border-collapse: collapse;
}

.detail-table th {
  padding: 0px;
}

.table-footer {
  background-color: #eee;
}

td.min,
th.min {
  width: 1%;
  white-space: nowrap;
}

td.pl-2,
th.pl-2 {
  padding-left: 2rem !important;
}

td.pr-2,
th.pr-2 {
  padding-right: 2rem !important;
}

td.spacer,
th.spacer {
  border-left: 2px solid #ccc;
}

.default-table {
  display: table;
  width: 100%;
  overflow-x: auto;
  border-collapse: separate; /* Don't collapse */
  border-spacing: 0;
}

.default-table td {
  border: 0.5px solid #dee2e6;
  padding: 0.15rem 5px;
  line-break: normal;
  white-space: break-spaces;
  font-size: 11.5px;
}

.default-table th {
  border: 1px solid #dee2e6;
  padding: 0.15rem 5px;
  text-align: center;
}

.default-table th[data-row-selection] {
  width: 30px;
}

.default-table-record-count {
  padding-top: 5px;
  font-size: 11px;
}

.default-table-pagination-controls {
  display: flex;
  justify-content: center;
  margin-top: 5px;
}
