// AppStack
@import "app";

.custom-control.custom-radio {
    padding-top: 0px !important;
}

.form-control:focus::placeholder {
    color: transparent;
}
