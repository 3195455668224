.pesq-modal-item {
    align-items: center;
    border-bottom: 0.75px solid #ccc;
}

.pesq-modal-item:hover {
    background-color: #eee;
    cursor: pointer;
}

.pesq-modal-item-title {
    padding-left: 7px;
    font-size: 13px;
}