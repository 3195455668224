.list-group-horizontal .list-group-item:first-child {
  border-left-width: 0 !important;
}

.list-group-horizontal .list-group-item:last-child {
  border-right-width: 0 !important;
}

.list-group-horizontal .list-group-item {
  border-top-width: 0 !important;
  border-bottom-width: 0 !important;
  flex-grow: 1;
  flex-basis: 50%;
  justify-content: space-between;
  height: 1.5rem !important;
}

.list-group-horizontal .list-group-item:hover {
  background-color: #fff !important;
}
