.card > .react-bootstrap-table .table {
  margin-bottom: 0;
}

.react-bootstrap-table-pagination-list .pagination {
  justify-content: flex-end;
}

.react-bootstrap-table-pagination {
  padding-top: 2rem;
}