.overflow-scroll {
    overflow: scroll;
}

.overflow-hidden {
    overflow: hidden;
}

.overflow-auto {
    overflow: auto;
}

.overflow-visible {
    overflow: visible;
}

.mn-100 {
    min-width: 100% !important;
}
