.dropzone {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  border-width: 2px;
  border-radius: 2px;
  border-color: #eeeeee;
  border-style: dashed;
  background-color: #fafafa;
  color: #2b2b2b;
  outline: none;
  transition: border 0.24s ease-in-out;
  transition: color 0.24s ease-in-out;
}

.dropzone:hover {
  border-color: #2196f3;
  color: #bdbdbd;
}

.dropzone.disabled {
  opacity: 0.6;
}
