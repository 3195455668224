.main {
  width: 100%;
  min-height: 100vh;
  min-width: 0;
  transition: $sidebar-transition;

  @include media-breakpoint-down(md) {
    overflow-y: hidden;
  }
}
.num-page-div{
  position: fixed;
  bottom: 1rem;
  z-index: -1;
}

.num-page-span{
  position: absolute;
  bottom: 0%;
}